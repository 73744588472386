/* You can add global styles to this file, and also import other style files */

// Main style
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Demos */
@import "./metronic/css/demos/demo1.css";

// Keenicons
@import "./metronic/vendors/keenicons/duotone/style.css";
@import "./metronic/vendors/keenicons/filled/style.css";
@import "./metronic/vendors/keenicons/outline/style.css";
@import "./metronic/vendors/keenicons/solid/style.css";


*{
    font-size: .9rem;
}


.lds-spinner {
    display: flex;
    justify-content: center;
  }
  
  .lds-spinner--center {
    align-items: center !important;
  }
  
  .lds-spinner::after {
    content: "";
    width: 20px;
    height: 20px;
    border: 2px solid #dddddd;
    border-top-color: #30323e00;
    border-bottom-color: #30323e00;
    border-radius: 50%;
    animation: lds-spinner 1s ease infinite;
  }
  
  @keyframes lds-spinner {
    to {
      transform: rotate(1turn);
    }
  }
  